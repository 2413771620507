@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  outline-width: 10px;
  outline-color: rgb(28, 28, 35);
  color: rgb(28, 28, 35);
  outline-offset: -22px;
  outline-style: solid;
}

html,
body {
  overflow-x: hidden;
}

.nav-link,
a,
.dropdown-item {
  color: rgb(28, 28, 35);
  border: 1px solid rgba(0, 0, 0, 0);
  transition: border-color 100ms;
}

.nav-link:hover,
a:hover,
.dropdown-item:hover {
  border: 1px solid rgb(28, 28, 35);
  color: rgb(28, 28, 35);
  text-decoration: none;
}

a.disabled,
.nav-link.disabled,
.dropdown-item.disabled,
.disabled {
  color: #8f98a0;
}

.fab-spacer {
  margin-right: 8px;
  min-width: 20px;
}

.social-icon {
  font-size: 2em;
}

.sign-up-box {
  width: calc(100vw - 40px);
  margin-top: 24px;
  background-color: rgb(28, 28, 35);
  position: absolute;
  padding: 40px;
  z-index: 100;
  left: 18px;
}

.sign-up-box h1,
.sign-up-box h2,
.sign-up-box h3,
.sign-up-box h4,
.sign-up-box h5,
.sign-up-box h6 {
  font-weight: 100;
  color: white;
}

.mc-form-button {
  background-color: white;
  color: rgb(28, 28, 35);
  border: none;
  font-weight: 300;
}

#mc-embedded-subscribe {
  transition: border-color 500ms, color 200ms;
  border: 1px solid rgba(0, 0, 0, 0);
}

#mc-embedded-subscribe:hover {
  color: rgb(28, 28, 35);
  border: 1px solid rgb(28, 28, 35);
}

input {
  width: 100%;
  border: none;
  border-radius: 0px;
  height: 32px;
}

input::placeholder {
  font-weight: 100;
  padding-left: 4px;
}

div.mce_inline_error {
  margin-top: 32px !important;
  font-weight: 100 !important;
}

.signup-clear {
  height: 240px;
}

.embed-wrapper {
  margin: 0px 0px 40px 0px;
}

.listen-button button {
  background-color: rgb(28, 28, 35);
  color: white;
  transition: all 600ms;
}

.listen-button button:hover {
  background-color: rgb(60, 60, 75);
  color: white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
}

.listen-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.listen-form {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.embed-header {
  margin: 8px;
  padding: 0px;
  text-align: center;
}

.video-embed {
  width: 100%;
  height: calc(calc(100vw - 200px) / 1.5);
  min-height: 160px;
  min-width: 200px;
  /* max-height: calc(100vh * 0.6); */
}

.video-embed-mock {
  padding: 100px 40px;
  text-align: center;
}

.show-box-wrapper {
  margin: 32px auto;
}

.show-box {
  border: 1px solid rgb(28, 28, 35);
  padding: 16px;
}

.show-box-header {
  border: 1px solid rgb(28, 28, 35);
  background-color: white;
  margin-top: -40px;
}

.show-box h1,
.show-box h2,
.show-box h3,
.show-box h4,
.show-box h5,
.show-box h6 {
  font-weight: 200;
  margin: 0px;
  padding: 8px;
}

.show-list {
  padding: 16px;
}

.show-box-wrapper-clear {
  height: 48px;
}

.ticket-link {
  font-weight: 400;
}

.hide {
  display: none;
}
